<template>
  <section id="declarations">
    <!-- <div class="dashboard__title">{{ title }}</div> -->
    <div class="header">
      <work-period class="select-picker" />
      <i-select
        class="select-picker"
        multiple
        :max-tag-count="1"
        filterable
        placeholder="Все юр.лица"
      >
        <i-option
          v-for="item in entities"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </i-option>
      </i-select>
      <i-select
        class="select-picker"
        placeholder="Все магазины"
      />
      <i-select
        class="select-picker"
        placeholder="Все статусы"
      />
      <b-button
        class="btn-add ml-auto"
        variant="primary"
        @click="create_declarations"
      >
        <img
          src="/img/icons/btn-plus.svg"
          alt=""
          class="mr"
        />
        <span>Создать новую</span>
      </b-button>
    </div>
    <b-card
      no-body
      class="mt-3 p-0"
    >
      <div
        class="d-flex"
        style="padding: 16px"
      >
        <b-form-input
          v-model="search"
          type="text"
          class="filter-search"
          :placeholder="'Поиск'"
        />
        <b-dropdown
          no-caret
          variant="none"
          class="ml-2"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>
      <resizable-table
        :inside_card="false"
        :default_fields="fields"
        :items="items"
        table_name="declarations_table"
        @row-click="rowClick"
      >
        <template #head_id>
          <b-checkbox v-model="select_all" />
        </template>
        <template #body_id="value">
          <b-checkbox
            v-model="selected"
            :value="value"
          />
        </template>
        <template #body_date="value">
          {{ getDate(new Date(value?.value)) }}
        </template>
        <template #body_entity="{ value }">
          {{ value?.name ?? '---' }}
        </template>
      </resizable-table>
    </b-card>
  </section>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import WorkPeriod from '@/components/WorkPeriod'
  import { EntityModel } from '@/models/entity.model'
  import ResizableTable from '@/components/ResizableTable'
  import { DeclarationModel } from '@/models/declaration.model'
  import { mapActions } from 'vuex'

  export default {
    name: 'AlcoDeclaration',
    components: { ResizableTable, WorkPeriod },
    apollo: {
      Entities: {
        query: require('../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entities = data.Entities?.map((el) => new EntityModel(el))
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Declarations: {
        query: require('../gql/Declarations.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              documentPagination: {
                skip: 0,
                take: 20
              }
            }
          }
        },
        result({ data }) {
          this.items = data.Declarations.documents?.map((el) => new DeclarationModel(el))
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        title: this.$route.meta.title,
        entities: [],
        items: [],
        declaration: {},
        select_all: false,
        selected: [],
        search: '',
        fields: [
          {
            label: '',
            key: 'id',
            width: 50,
            checked: true
          },
          {
            label: 'Тип декларации',
            key: 'declaration_type',
            width: 150,
            checked: true
          },
          {
            label: 'Дата',
            key: 'date',
            width: 130,
            checked: true
          },
          {
            label: 'Юр. лицо',
            key: 'entity',
            width: 150,
            checked: true
          },
          {
            label: 'Магазин',
            key: 'branch',
            width: 150,
            checked: true
          },
          {
            label: 'Статус',
            key: 'status',
            width: 150,
            checked: true
          },
          {
            label: 'Тип отчетности',
            key: 'report_type',
            width: 150,
            checked: true
          },
          {
            label: 'Автор',
            key: 'owner',
            width: 150,
            checked: true
          }
        ]
      }
    },
    computed: {},
    methods: {
      ...mapActions({ setDeclaration: 'analytics/setDeclaration' }),
      getDate(date) {
        return formatDate(date, 'numeric')
      },
      async create_declarations() {
        this.setDeclaration({
          entity: '',
          type: 'first',
          quarter: 'first'
        })
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateDeclaration.graphql'),
          variables: {
            input: {}
          }
        })
        this.$router.push({
          name: 'analytics.alco-declaration.new',
          params: { id: data.CreateDeclaration.id }
        })
      },
      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      rowClick(row) {
        this.setDeclaration({
          entity: '',
          type: 'first',
          quarter: 'first'
        })
        this.$router.push({
          name: 'analytics.alco-declaration.new',
          params: { id: row.id }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .filter-search {
    padding-left: 32px;
    max-width: 268px;
    margin-right: 12px;
    background: url(/img/icons/input-search.svg) 12px center no-repeat;
    font-size: 14px;
    line-height: 16px;
  }
  ::v-deep() {
    .calendar-picker {
      width: 300px;
    }

    .ivu-select {
      width: 180px;
    }

    .select-picker {
      margin-right: 15px;
    }

    .table-search-input {
      width: 300px;
    }
  }

  #declarations {
    .header {
      display: flex;
    }
  }
</style>
