var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "declarations"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('work-period', {
    staticClass: "select-picker"
  }), _c('i-select', {
    staticClass: "select-picker",
    attrs: {
      "multiple": "",
      "max-tag-count": 1,
      "filterable": "",
      "placeholder": "Все юр.лица"
    }
  }, _vm._l(_vm.entities, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c('i-select', {
    staticClass: "select-picker",
    attrs: {
      "placeholder": "Все магазины"
    }
  }), _c('i-select', {
    staticClass: "select-picker",
    attrs: {
      "placeholder": "Все статусы"
    }
  }), _c('b-button', {
    staticClass: "btn-add ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.create_declarations
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _c('span', [_vm._v("Создать новую")])])], 1), _c('b-card', {
    staticClass: "mt-3 p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "padding": "16px"
    }
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": 'Поиск'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-dropdown', {
    staticClass: "ml-2",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('resizable-table', {
    attrs: {
      "inside_card": false,
      "default_fields": _vm.fields,
      "items": _vm.items,
      "table_name": "declarations_table"
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (value) {
        return [_c('b-checkbox', {
          attrs: {
            "value": value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_date",
      fn: function (value) {
        return [_vm._v(" " + _vm._s(_vm.getDate(new Date(value === null || value === void 0 ? void 0 : value.value))) + " ")];
      }
    }, {
      key: "body_entity",
      fn: function (_ref) {
        var _value$name;

        var value = _ref.value;
        return [_vm._v(" " + _vm._s((_value$name = value === null || value === void 0 ? void 0 : value.name) !== null && _value$name !== void 0 ? _value$name : '---') + " ")];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }