import { EntityModel } from '@/models/entity.model'

export class DeclarationModel {
  constructor(request) {
    this.id = request.id
    this.company = request.company
    this.branches = request.branches
    this.type = request.type
    this.quarter = request.quarter
    this.entity = new EntityModel(request.entity)
    this.date = new Date(request.date)
  }
}
